import React, { Fragment, useState, useEffect, useRef } from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import styled from "@emotion/styled"
import { withStoreContext } from "../../../context/StoreContext"
import { getPrice, navigateToCart } from "../../../utils/navigateToCart"
import { useLocation } from "@reach/router"
import { isBrowser } from "../../../context/ApolloContext"
const $ = isBrowser ? require("jquery") : {}

import {
  ProductInfoSection,
  Container,
  ProductInfoBox,
  ProductImageSection,
  ExclusifAddToCart,
  ProductEstimateShippingBox,
  ProductEstimateShippingIcon,
  ProductEstimateShippingText,
  MatelasComboWrapper,
  MatelasComboBoxImage,
  LyneComboBox,
  ExclusifComboBoxTag,
  ExclusifComboBoxTitle,
  LyneCheckImage,
  LyneComboLeftSection,
  LyneComboBottomSection,
  EcoParticipationText,
  AddToCartWrapper,
  LyneComboBoxTagWrapper,
} from "../../../components/Products/styled"

import SeoComp from "../../../components/SeoComp"

import MatelasImageSlider from "../../../components/Products/MatelasImageSlider"
import MatelasProductTopSection from "../../../components/Products/MatelasProductTopSection"
import MatelasAlmaSection from "../../../components/Products/MatelasAlmaSection"
import MatelasTestimonialSection from "../../../components/Products/MatelasTestimonialSection"
import LyneupPageSectionList from "../../../components/Products/LyneupPageSectionList"
import SoutienDesSection from "../../../components/Products/SoutienDesSection"
import OreillerCompositionSection from "../../../components/Products/OreillerCompositionSection"
import ProductSainSection from "../../../components/Products/ProductSainSection"
import SatisfactionClientSection from "../../../components/Products/SatisfactionClientSection"
import MatelasReviewSection from "../../../components/Products/MatelasReviewSection"
import CrossProductSection from "../../../components/Products/CrossProductSection"
import ProductFaqSection from "../../../components/Products/ProductFaqSection"
import Testimonials from "../../../components/Layout/Testimonials"
import Footer from "../../../components/Layout/Footer"
import {
  addToCartScript,
  productGtmEvent,
} from "../../../utils/additionalScriptHelpers"
import { cartEstimateDeliveryText } from "../../../utils/cartHelpers"
import ExclusifStickyAddToCart from "../../../components/Products/ExclusifStickyAddToCart"

const Oreiller = ({
  intl,
  data,
  data: { shopifyProduct: product },
  storeContext,
  storeContext: { checkout, addVariantToCart },
}) => {
  const [selectedVariant, setselectedVariant] = useState(
    product?.variants[0] || null
  )
  const [selectedVariantQuantity, setselectedVariantQuantity] = useState(1)
  const [selectProductType, setselectProductType] = useState("Seul")
  const [productPrice, setproductPrice] = useState(79)
  const [activeFlag, setactiveFlag] = useState(false)
  const [ecoParticipationPrice, setecoParticipationPrice] = useState("")

  const setInitialParamValues = () => {
    const queryString = isBrowser && window.location.search
    const urlParams = new URLSearchParams(queryString)
    const offerParam = urlParams.get("Offer")

    if (!offerParam) {
      let paramString = ""
      paramString = paramString + "?Offer=Seul"
      isBrowser && history.replaceState(null, null, paramString)
    }

    if (offerParam === "Pack") {
      setselectProductType("Pack")
      ecoParticipation("Pack")
    } else {
      setselectProductType("Seul")
      ecoParticipation("Seul")
    }
  }

  const handleQuantityChange = event => {
    setselectedVariantQuantity(event.target.value)
    productGtmEvent(
      product,
      selectedVariant,
      selectedVariantQuantity,
      intl.locale
    )
  }

  const handleAddtoCart = async () => {
    addVariantToCart(
      selectedVariant.id,
      selectedVariantQuantity,
      null,
      checkout => {
        addToCartScript(
          product.id,
          product.title,
          selectedVariant,
          selectedVariantQuantity,
          checkout,
          intl.locale
        )
        navigateToCart(intl.locale)
      }
    )
  }

  const handleProductType = productType => {
    let price = ""
    if (productType === "Pack") {
      price = groupDiscountPrice()
      setselectedVariantQuantity(2)
    } else {
      price = discountPrice()
    }

    ecoParticipation(productType)

    let paramString = ""
    paramString = paramString + "?Offer=" + productType
    isBrowser && history.replaceState(null, null, paramString)

    productGtmEvent(
      product,
      selectedVariant,
      selectedVariantQuantity,
      intl.locale
    )

    setproductPrice(price)
    setselectProductType(productType)
  }

  let discountPercentage = 0.3

  const discountPrice = () => {
    let discountPrice = Number(
      selectedVariant
        ? selectedVariant.price
        : product.priceRangeV2.maxVariantPrice.amount
    )

    discountPrice =
      Number(discountPrice) - Number(discountPrice) * Number(discountPercentage)

    return getPrice(discountPrice)
  }

  const groupPrice = () => {
    let groupPrice = selectedVariant
      ? selectedVariant.price
      : product.priceRangeV2.maxVariantPrice.amount

    groupPrice = Number(groupPrice) * 2

    return groupPrice
  }

  const groupDiscountPrice = () => {
    let groupDiscountPrice = groupPrice()

    groupDiscountPrice =
      Number(groupDiscountPrice) -
      Number(groupDiscountPrice) * Number(discountPercentage)

    return getPrice(groupDiscountPrice)
  }

  const BottomBarActive = () => {
    function isOnScreen(elem) {
      if (elem.length == 0) {
        return
      }
      var $window = $(window)
      var viewport_top = $window.scrollTop()
      var viewport_height = $window.height()
      var viewport_bottom = viewport_top + viewport_height
      var $elem = $(elem)
      var top = $elem.offset().top
      var height = $elem.height()
      var bottom = top + height

      return (
        (top >= viewport_top && top < viewport_bottom) ||
        (bottom > viewport_top && bottom <= viewport_bottom) ||
        (height > viewport_height &&
          top <= viewport_top &&
          bottom >= viewport_bottom)
      )
    }

    $(document).ready(function() {
      window.addEventListener("scroll", function(e) {
        if (!isOnScreen($("#add_to_cart")) && !isOnScreen($("#footer"))) {
          setactiveFlag(true)
        } else {
          setactiveFlag(false)
        }
      })
    })
  }

  const ecoParticipation = type => {
    if (type === "Pack") {
      setecoParticipationPrice("0,40€")
    } else {
      setecoParticipationPrice("0,20€")
    }
  }

  var searchLocation = useLocation()

  useEffect(() => {
    setInitialParamValues()
  }, [searchLocation.search])

  useEffect(() => {
    BottomBarActive()
  }, [selectedVariant])

  useEffect(() => {
    if (intl.locale != "fr") {
      navigate(`/${intl.locale}/shop/`)
    } else {
      productGtmEvent(
        product,
        selectedVariant,
        selectedVariantQuantity,
        intl.locale
      )
    }
  }, [intl.locale])

  return (
    process.env &&
    (process.env.GATSBY_ACTIVE_ENV === "eur" ||
      process.env.GATSBY_ACTIVE_ENV === "general") &&
    intl.locale === "fr" &&
    data && (
      <Fragment>
        <SeoComp
          description={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].meta_description
          }
          title={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].meta_title
          }
          twitter_card={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].twitter_card
          }
          image={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].image &&
            data.allDirectusSeoTranslation.nodes[0].image.data &&
            data.allDirectusSeoTranslation.nodes[0].image.data.url
          }
          jsonLd="oreiller"
          productPrice={productPrice}
          hreflangPathname={{
            fr: "shop/oreiller",
            en: "",
            de: "",
            es: "",
          }}
          noindex={true}
          nofollow={true}
        />

        <ExclusifStickyAddToCart
          data={
            data.allDirectusOreillerTranslation.nodes[0].exclusif_combo_section
          }
          productType={selectProductType}
          cart_text={
            data.allDirectusOreillerTranslation.nodes[0].add_to_cart_button
          }
          discountPrice={
            selectProductType === "Seul"
              ? discountPrice()
              : groupDiscountPrice()
          }
          regularPrice={
            selectProductType === "Seul"
              ? Number(
                  selectedVariant
                    ? selectedVariant.price
                    : product?.priceRangeV2?.maxVariantPrice.amount
                )
              : groupPrice()
          }
          handleAddTocart={selectedVariant ? handleAddtoCart : null}
          active={activeFlag}
          id="sticy-button"
        />

        <ProductInfoSection id="info-section" page="lyne_home">
          <Container>
            <ProductInfoBox>
              <ProductImageSection>
                <MatelasImageSlider
                  data={
                    data.allDirectusOreillerTranslation.nodes[0].banner_slider
                      .list
                  }
                />
              </ProductImageSection>

              <ProductRightInfoSection>
                <MatelasProductTopSection
                  title={data.allDirectusOreillerTranslation.nodes[0].title}
                  subtitle={
                    data.allDirectusOreillerTranslation.nodes[0].subtitle
                  }
                  description={
                    data.allDirectusOreillerTranslation.nodes[0].description
                  }
                  review={true}
                />
                {data.allDirectusOreillerTranslation.nodes[0]
                  .exclusif_combo_section && (
                  <MatelasComboWrapper>
                    {data.allDirectusOreillerTranslation.nodes[0]
                      .exclusif_combo_section.single_product_text && (
                      <LyneComboBox onClick={() => handleProductType("Seul")}>
                        <LyneComboBoxTagWrapper>
                          <ExclusifComboBoxTag
                            dangerouslySetInnerHTML={{
                              __html:
                                data.allDirectusOreillerTranslation.nodes[0]
                                  .exclusif_combo_section.offer_title,
                            }}
                          />
                        </LyneComboBoxTagWrapper>
                        <ExclusifComboBoxTitle
                          dangerouslySetInnerHTML={{
                            __html:
                              data.allDirectusOreillerTranslation.nodes[0]
                                .exclusif_combo_section.single_product_text,
                          }}
                        />
                        <LyneComboBottomSection>
                          <LyneComboLeftSection>
                            <MatelasComboBoxImage
                              className="lazyload"
                              data-src="https://static.percko.com/uploads/9855f64d-cf60-4b58-9d91-40dceb548079.jpg"
                            />
                            <ProductDiscountPrice>
                              {`${
                                process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                  ? process.env.GATSBY_CURRENCY
                                  : ""
                              }${discountPrice()}${
                                process.env.GATSBY_CURRENCY_TYPE === "eur"
                                  ? process.env.GATSBY_CURRENCY
                                  : ""
                              }`}
                            </ProductDiscountPrice>
                            <ProductStrikePrice>
                              {`${
                                process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                  ? process.env.GATSBY_CURRENCY
                                  : ""
                              }${Number(
                                selectedVariant
                                  ? selectedVariant.price
                                  : product.priceRangeV2.maxVariantPrice.amount
                              )}${
                                process.env.GATSBY_CURRENCY_TYPE === "eur"
                                  ? process.env.GATSBY_CURRENCY
                                  : ""
                              }`}
                            </ProductStrikePrice>
                          </LyneComboLeftSection>
                          <LyneCheckImage
                            src={
                              selectProductType === "Seul"
                                ? "https://static.percko.com/uploads/fd189ad9-7d89-4e1c-b414-751a362b4668.svg"
                                : "https://static.percko.com/uploads/cc8a1cf0-e49a-4790-a3a7-8bc21ba3f443.svg"
                            }
                          />
                        </LyneComboBottomSection>
                      </LyneComboBox>
                    )}
                    {data.allDirectusOreillerTranslation.nodes[0]
                      .exclusif_combo_section.offer_title &&
                      data.allDirectusOreillerTranslation.nodes[0]
                        .exclusif_combo_section.offer_text && (
                        <LyneComboBox
                          border="0px"
                          onClick={() => handleProductType("Pack")}
                        >
                          <LyneComboBoxTagWrapper>
                            <ExclusifComboBoxTag
                              dangerouslySetInnerHTML={{
                                __html:
                                  data.allDirectusOreillerTranslation.nodes[0]
                                    .exclusif_combo_section.offer_title,
                              }}
                            />
                          </LyneComboBoxTagWrapper>
                          <ExclusifComboBoxTitle
                            dangerouslySetInnerHTML={{
                              __html:
                                data.allDirectusOreillerTranslation.nodes[0]
                                  .exclusif_combo_section.offer_text,
                            }}
                          />
                          <LyneComboBottomSection>
                            <LyneComboLeftSection>
                              <MatelasComboBoxImage
                                className="lazyload"
                                data-src="https://static.percko.com/uploads/47b37768-d021-44b1-ab97-69cf91fd70d1.jpg"
                              />
                              <ProductDiscountPrice>
                                {`${
                                  process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                    ? process.env.GATSBY_CURRENCY
                                    : ""
                                }${groupDiscountPrice()}${
                                  process.env.GATSBY_CURRENCY_TYPE === "eur"
                                    ? process.env.GATSBY_CURRENCY
                                    : ""
                                }`}
                              </ProductDiscountPrice>
                              <ProductStrikePrice>
                                {`${
                                  process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                    ? process.env.GATSBY_CURRENCY
                                    : ""
                                }${groupPrice()}${
                                  process.env.GATSBY_CURRENCY_TYPE === "eur"
                                    ? process.env.GATSBY_CURRENCY
                                    : ""
                                }`}
                              </ProductStrikePrice>
                            </LyneComboLeftSection>
                            <LyneCheckImage
                              src={
                                selectProductType === "Pack"
                                  ? "https://static.percko.com/uploads/fd189ad9-7d89-4e1c-b414-751a362b4668.svg"
                                  : "https://static.percko.com/uploads/cc8a1cf0-e49a-4790-a3a7-8bc21ba3f443.svg"
                              }
                            />
                          </LyneComboBottomSection>
                        </LyneComboBox>
                      )}
                  </MatelasComboWrapper>
                )}
                <AddToCartWrapper>
                  <ExclusifAddToCart
                    id="add_to_cart"
                    loading={storeContext.adding ? "adding" : ""}
                    onClick={handleAddtoCart}
                  >
                    {
                      data.allDirectusOreillerTranslation.nodes[0]
                        .add_to_cart_button
                    }
                  </ExclusifAddToCart>
                </AddToCartWrapper>

                {selectedVariant && productPrice && (
                  <ProductEstimateShippingBox page="matelas">
                    <ProductEstimateShippingIcon src="https://static.percko.com/uploads/f60c3d29-f9bf-4282-bedd-62a1af4b98a8.svg" />
                    <ProductEstimateShippingText>
                      {cartEstimateDeliveryText(
                        "oreiller",
                        data.allDirectusCommonTranslation.nodes[0].months_text,
                        intl.locale
                      )}
                    </ProductEstimateShippingText>
                  </ProductEstimateShippingBox>
                )}
                {ecoParticipationPrice && (
                  <EcoParticipationText
                    dangerouslySetInnerHTML={{
                      __html: intl
                        .formatMessage({
                          id: "eco_paricipation_text",
                        })
                        .replace("eco_price", ecoParticipationPrice),
                    }}
                  />
                )}
                {productPrice && selectProductType == "Pack" && (
                  <MatelasAlmaSection
                    price={
                      selectProductType === "Seul"
                        ? discountPrice()
                        : groupDiscountPrice()
                    }
                  />
                )}
              </ProductRightInfoSection>
            </ProductInfoBox>
          </Container>
        </ProductInfoSection>

        {data.allDirectusOreillerTranslation.nodes[0].testimonial_section && (
          <MatelasTestimonialSection
            data={
              data.allDirectusOreillerTranslation.nodes[0].testimonial_section
            }
          />
        )}

        {data.allDirectusOreillerTranslation.nodes[0].page_section_list && (
          <LyneupPageSectionList
            data={
              data.allDirectusOreillerTranslation.nodes[0].page_section_list
            }
            page="oreiller"
          />
        )}

        {data.allDirectusOreillerTranslation.nodes[0].soulagment_section && (
          <SoutienDesSection
            data={
              data.allDirectusOreillerTranslation.nodes[0].soulagment_section
            }
            id="soutien_des_section"
          />
        )}

        {data.allDirectusOreillerTranslation.nodes[0].technology_section && (
          <OreillerCompositionSection
            data={
              data.allDirectusOreillerTranslation.nodes[0].technology_section
            }
            id="technologie"
          />
        )}

        {data.allDirectusOreillerTranslation.nodes[0].produit_sain_section && (
          <ProductSainSection
            data={
              data.allDirectusOreillerTranslation.nodes[0].produit_sain_section
            }
            id="product_sain_section"
          />
        )}

        {data.allDirectusOreillerTranslation.nodes[0].satisfaction_section && (
          <SatisfactionClientSection
            data={
              data.allDirectusOreillerTranslation.nodes[0].satisfaction_section
            }
            id="satisfaction_client"
          />
        )}

        {data.allDirectusOreillerTranslation.nodes[0].review_section && (
          <MatelasReviewSection
            data={data.allDirectusOreillerTranslation.nodes[0].review_section}
            id="avis_section"
            page="oreiller"
          />
        )}

        {/* {data.allDirectusOreillerTranslation.nodes[0].cross_section && (
          <CrossProductSection
            data={data.allDirectusOreillerTranslation.nodes[0].cross_section}
            price={data.allShopifyProduct.edges}
            page="oreiller"
          />
        )} */}

        {data.allDirectusOreillerTranslation.nodes[0].product_faq_section && (
          <ProductFaqSection
            data={
              data.allDirectusOreillerTranslation.nodes[0].product_faq_section
            }
            pageName="matelas"
            id="faq_section"
          />
        )}

        {data.allDirectusCommonTranslation.nodes.length > 0 && (
          <Testimonials
            data={data.allDirectusCommonTranslation.nodes[0].testimonials.list}
          />
        )}
        {data.allDirectusFooterTranslation.nodes.length > 0 && (
          <Footer data={data.allDirectusFooterTranslation.nodes[0]} />
        )}
      </Fragment>
    )
  )
}

const ProductRightInfoSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 5;
  padding-left: 10px;
  justify-content: space-between;
  padding: 20px 0px 20px 10px;

  @media (max-width: 600px) {
    padding: 20px;
  }
`

const ProductStrikePrice = styled.div`
  font-size: 14px;
  line-height: 1.2;
  font-family: "Gotham Book";
  color: #777;
  font-weight: 500;
  position: relative;

  :before {
    position: absolute;
    content: "";
    right: 0;
    border-top: 1px solid #777;
    left: 50%;
    top: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 850px) {
    margin-right: 10px;
  }
`

const ProductDiscountPrice = styled.div`
  font-size: 18px;
  line-height: 1.2;
  font-family: "Gotham Book";
  color: ${props => props.theme.colors.primary.exclusif};
  font-weight: bold;
  position: relative;
  margin-right: 10px;

  @media (max-width: 767px) {
    margin-right: 7px;
  }
`

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusOreillerTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        banner_slider {
          list {
            image
            alt
          }
        }
        title
        subtitle
        description
        add_to_cart_button
        combo_section {
          single_product_text
          offer_title
          offer_text
          offer_second_text
        }
        exclusif_combo_section {
          single_product_text
          offer_title
          offer_text
          offer_second_text
        }
        testimonial_section {
          list {
            image
            alt
            text
            mobile_text
            popup_text
          }
        }
        page_section_list {
          list {
            title
            id
          }
        }
        soulagment_section {
          section_title
          section_mobile_title
          section_subtitle
          section_mobile_subtitle
          desktop_image
          mobile_image
          image_alt
          image_title
          testimonial_image
          testimonial_image_alt
          testimonial_name
          testimonial_designation
          testimonial_text
          quote_image
        }
        technology_section {
          section_title
          image
          alt
          list {
            title
            text
            tech_list {
              icon_image
              icon_alt
              text
              popup_text
            }
          }
        }
        produit_sain_section {
          section_title
          section_mobile_title
          section_subtitle
          section_mobile_subtitle
          bottom_text
          mobile_bottom_text
          list {
            image
            alt
            top_image
            title
            text
            mobile_text
          }
        }
        satisfaction_section {
          title
          subtitle
          mobile_subtitle
        }
        review_section {
          title
          text
          review_list {
            image
            alt
            quote_image
            name
            designation
            company
            city
            text
          }
        }
        product_faq_section {
          title
          subtitle
          list {
            question
            answer {
              image
              mobile_image
              alt
              text
            }
            open
          }
        }
        cross_section {
          section_title
          name
          image
          alt
          tag
          title
          subtitle
          url
          button_text
          product_list {
            name
            image
            alt
            logo
            logo_alt
            title
            subtitle
            url
          }
        }
      }
    }
    allShopifyProduct(sort: { order: ASC, fields: handle }) {
      edges {
        node {
          handle
          id: storefrontId
          priceRangeV2 {
            minVariantPrice {
              amount
            }
            maxVariantPrice {
              amount
            }
          }
          variants {
            id: storefrontId
            price
          }
        }
      }
    }
    shopifyProduct(handle: { eq: "oreiller" }) {
      id: storefrontId
      createdAt
      updatedAt
      descriptionHtml
      description
      handle
      productType
      title
      vendor
      publishedAt
      options {
        id
        name
        values
      }
      variants {
        id: storefrontId
        title
        price
        weight
        sku
        image {
          id
          src: originalSrc
          #   altText
        }
        selectedOptions {
          name
          value
        }
        price
      }
      images {
        src: originalSrc
        id
      }
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
      }
    }
    allDirectusFooterTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        facebook
        gbp_facebook
        youtube
        twitter
        instagram
        linkedin
        about_us {
          name
          list {
            link
            list
          }
        }
        contact {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        purchases {
          list {
            list
            link
          }
          name
        }
        help {
          list {
            link
            list
          }
          name
        }
        about_us_menu {
          name
          list {
            link
            list
          }
        }
        knowledge_center_menu {
          name
          list {
            link
            list
          }
        }
        shop_menu {
          name
          list {
            link
            list
          }
        }
        support_menu {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        newsletter_heading
        follow_us_heading
        footer_bottom {
          list {
            link
            list
          }
        }
      }
    }
    allDirectusCommonTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        testimonials {
          list {
            image
            text
          }
        }
        months_text {
          month
        }
      }
    }
    allDirectusSeoTranslation(
      filter: {
        language: { eq: $lang }
        seo: { page_name: { eq: "oreiller_exclusif" } }
      }
    ) {
      nodes {
        meta_title
        meta_description
        twitter_card
        twitter_title
        url
        image {
          data {
            url
          }
        }
      }
    }
  }
`

export default withStoreContext(injectIntl(Oreiller))
